import React, { Component } from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
class Historia extends Component {
  render() {
    return (
      <>
      <div className="App">
        <Nabvar />
        <div className="row">

          <div className="col m-4">
          <div className="row"><h1>HISTORIA</h1></div>
          <div className="row pt-3"><h2>Antes de la Fundación</h2></div>
          <p className="text-start">Aproximadamente en el año 1870, el Sr. Andrés De La Plaza, quién había llegado a ésta región unos diez años antes, era dueño de un establecimiento de campo en la zona donde hoy se levanta la ciudad de Miramar. Tal propiedad era conocida como “El Saboyardo”, nombre que le fuera sugerido a De La Plaza por el entonces administrador de la estancia, Don Luis Druge, quién había nacido en el departamento de Saboya, en el Sudeste de Francia.</p>
           <p className="text-start">“El Saboyardo” medía una legua y tres cuartos de superficie, se situaba sobre la costa a siete leguas suroeste de Mar del Plata, entre los arroyos “El Durazno” y “La Totora”, distantes a ocho kilómetros, uno del otro. La totalidad de la superficie de “El Saboyardo” se encontraba en el Partido de Balcarce, que había sido creado el 19 de julio de 1865, y del que posteriormente se desprendería el Partido de General Pueyrredón, en el año 1879.</p>
           <p className="text-start">Tras la muerte de Andrés De La Plaza, en 1878, se hizo cargo del establecimiento uno de sus hijos, Fortunato, quién abandonó sus estudios de Derecho y se radicó en estas tierras. La familia de De la Plaza residía en el casco de la estancia, que había sido construido por Don Andrés en el año 1871, lugar en que hoy se encuentra ubicada la finca “Los Abuelos” (calles 1 y 64).</p>
          <div className="row"><h2>La idea de la fundación</h2></div>
           <p className="text-start">Don José María Dupuy, quién era cuñado de Don Fortunato, pertenecía a una familia de largo arraigo y destacada actuación pública en la zona y era una persona sumamente emprendedora, cuya capacidad le permitía observar las posibilidades de desarrollo de estas tierras. Así relata el mismo Dupuy al corresponsal del diario “La Prensa” el surgimiento de la idea de fundación, según publicación del 31 de enero de 1926: “… Al frente de la casa de comercio que me había dejado mi madre, próxima a ̈El Saboyardo ̈, hacía yo entonces visitas casi diarias al campo de mi cuñado, a la vez que con frecuencia iba a Mar del Plata, cuyos adelantos seguía paso a paso, interesado por sus transformaciones extraordinarias. La visión de progreso, siempre creciente, en aquella localidad y el conocimiento que de éste lugar tenía, me sugirieron muchas veces la idea de fundar un pueblo balneario. Conocía palmo a palmo esta playa, veía las barrancas a pocos metros de la orilla, permitiendo la edificación de construcciones, constantemente acariciadas por el aire sano del océano y preví que sólo el buen éxito podía acompañar a quién acometiera tal empresa…”</p>
           <p className="text-start">Así es que, promediando el año 1887 y ante la posibilidad de que De la Plaza vendiera “El Saboyardo”, Dupuy logró convencer a su cuñado de que sería más beneficiosa la fundación de un pueblo balneario en los terrenos que le pertenecían.</p>
          <div className="row"><h2>Los primeros pasos</h2></div>
           <p className="text-start">Don José María Dupuy, quién era cuñado de Don Fortunato, pertenecía a una familia de largo arraigo y destacada actuación pública en la zona y era una persona sumamente emprendedora, cuya capacidad le permitía observar las posibilidades de desarrollo de estas tierras. Así relata el mismo Dupuy al corresponsal del diario “La Prensa” el surgimiento de la idea de fundación, según publicación del 31 de enero de 1926: “… Al frente de la casa de comercio que me había dejado mi madre, próxima a ̈El Saboyardo ̈, hacía yo entonces visitas casi diarias al campo de mi cuñado, a la vez que con frecuencia iba a Mar del Plata, cuyos adelantos seguía paso a paso, interesado por sus transformaciones extraordinarias. La visión de progreso, siempre creciente, en aquella localidad y el conocimiento que de éste lugar tenía, me sugirieron muchas veces la idea de fundar un pueblo balneario. Conocía palmo a palmo esta playa, veía las barrancas a pocos metros de la orilla, permitiendo la edificación de construcciones, constantemente acariciadas por el aire sano del océano y preví que sólo el buen éxito podía acompañar a quién acometiera tal empresa…”</p>
           <p className="text-start">Así es que, promediando el año 1887 y ante la posibilidad de que De la Plaza vendiera “El Saboyardo”, Dupuy logró convencer a su cuñado de que sería más beneficiosa la fundación de un pueblo balneario en los terrenos que le pertenecían.

</p>
          <div className="row"><h2>Los primeros pasos</h2></div>
           <p className="text-start">Fortunato De La Plaza, a la sazón Juez de Paz de la ciudad de Mar del Plata, encargó a Dupuy la realización de todas las tramitaciones necesarias para llevar a cabo el proyecto. Éste se trasladó entonces a la ciudad de Buenos Aires, interesando en el tema a su amigo, el Ing. Rómulo Otamendi, quién le brindó todo el asesoramiento y el apoyo necesario para llevar a feliz término la idea, quedando encargado de la realización de los planos y de las diligencias que correspondiesen llevar a cabo ante las autoridades.</p>
           <p className="text-start">El Ing. Otamendi, quien se encontraba desempeñando un cargo en la función pública, envió para realizar la mensura al Agrimensor Eugenio Moy. Así es que Moy, junto a José María Dupuy, procedieron a la confección del plano y a la delineación de calles, ubicación de plazas, quintas, chacras, etc., regresando de inmediato a Buenos Aires para terminar con Otamendi el plano del futuro pueblo.</p>
          <div className="row"><h2>La elección del nombre</h2></div>
           <p className="text-start">Luego de finalizado el plano, en Octubre de 1887, José María Dupuy, aún en Buenos Aires, envió el siguiente telegrama a Fortunato De La Plaza: “Buenos Aires, 30 de Octubre de 1887 – Llegado el momento de dar el nombre al pueblo, yo opino Mira Mar. Rómulo indica Trouville o Gijón. Contéstame tu resolución – José María Dupuy”. Fortunato De La Plaza contestó el mismo día con el siguiente telegrama: “Mar del Plata, 30 de Octubre de 1887 – de los nombres propuestos, me quedo con Mira Mar. Aunque no sé qué importancia tendrá como punto balneario el Mira Mar de Austria, sólo hará falta que fije su punto de referencia en el mismo un Maximiliano para darle nombre.</p>
           <p className="text-start">Aclare bien todo para que no vayamos a tener alguna dificultad en la venta de terrenos sin estar declarado pueblo. Salud – F. De La Plaza”.</p>
           <p className="text-start">El prestigio de Fortunato de la Plaza le dio un importante impulso inicial al naciente pueblo: no sólo los primeros pobladores provenían de Mar del Plata (ciudad de la que Don Fortunato era Intendente en ese momento), sino que invitó a sus amistades a una excursión hasta Miramar, el 21 de febrero de 1888, hecho que motivó la publicación en la prensa escrita de la época del listado de apellidos ilustres que formaron parte de la comitiva.</p>
           <p className="text-start">Entre los primeros propietarios de las tierras de Miramar, podemos mencionar a Ambrosio Pérez, Luis Ferrari, Paulino Ruiz, José María Dupuy, José Whalt, Tomás Luna, Gabriel y Pablo Echeveste, A. Guffanti, y otros.</p>
           <p className="text-start">Así fue adquiriendo su conformación el incipiente pueblo, con la construcción de las primeras edificaciones y la instalación de los primeros comercios, entre los que cabe destacar el famoso almacén “La Chata”, fundado el 15 de abril de 1888, el que estaba ubicado en la esquina de las actuales calles 9 de Julio y 38 y que por muchos años sería el almacén de ramos generales por excelencia de Miramar.</p>
          <div className="row"><h2>La aprobación final</h2></div>
           <p className="text-start">El día 20 de septiembre, el Poder Ejecutivo aprobó el pedido del Sr. De La Plaza a través de la siguiente resolución: “La Plata, Septiembre 20 de 1888 – Vista la solicitud de don Fortunato De La Plaza para fundar un pueblo en el partido de Pueyrredón, denominado “Mira Mar”, en terreno de su propiedad y de conformidad con lo informado por el Departamento de Ingenieros, el P.E. resuelve:</p>
           <p className="text-start">1) Autorizar a don Fortunato De La Plaza para fundar el pueblo a que se refiere la solicitud de foja 2 en terreno de su propiedad situado en el partido de Gral. Pueyrredón.

</p>
           <p className="text-start">2) Aceptar la traza propuesta por la división de dichos terrenos en manzanas para pueblos, quintas y chacras.</p>
           <p className="text-start">3) Aceptar igualmente la donación que hace de los terrenos siguientes: Sección 2a, Manzana Z ,id. id. Lotes 1 y 2 Manzana A, Sección 3a, Manzana Z, id. id. Lotes 4 y 5 Manzana F, Quinta No 141 – debiendo el recurrente determinar con claridad cuál es la posición de los lotes de la manzana A de la sección 2a. y los Lotes 4 y 5 de la manzana F de la sección 3a.</p>
           <p className="text-start">En su consecuencia pase este expediente a la escribanía Mayor de Gobierno para que se escriture a favor del Estado los lotes cedidos. Comuníquese, etc.</p>
           <p className="text-start">Fdo.: Paz y Gonnet</p>
           <p className="text-start">El 6 de Octubre, Don. Fortunato de la Plaza firmó ante el escribano Manuel Canata un poder a nombre de José Niño, para que éste lo represente ante la Escribanía Mayor de Gobierno al momento de escriturar los terrenos en favor de la Provincia.</p>
           <p className="text-start">Luego de solicitar los informes respectivos al Depto. de Ingenieros, para corroborar la ubicación de los solares, comprobar que De La Plaza no tenía deuda alguna con la Provincia, y que los terrenos no sufrían embargo o hipoteca alguna con la Provincia, se firmó, el día 6 de Noviembre de 1888, la escritura traslativa de dominio de los terrenos para edificios públicos.</p>
           <p className="text-start">La venta de tierra proseguía, mientras tanto, en forma sostenida, a través de Moy y Carbalho en Buenos Aires, de Fortunato De La Plaza en Mar del Plata, y de José María Dupuy en Miramar, instalándose pequeños comercios de ramos generales, almacenes y fondas, que conjuntamente con las edificaciones particulares, le iban dando la conformación de pueblo, hecho que iba atrayendo cada vez mayor cantidad de pobladores.</p>
           <p className="text-start">A esto se agregó que en 1889, se forman las Sociedades Socorros Mutuos por parte de las respectivas colectividades española e italiana, que eran cada vez más numerosas en todo el país debido al impulso que las autoridades nacionales le daban a la inmigración en ese entonces.</p>
           <p className="text-start">A los efectos de dar una organización administrativa al pueblo, se formó el 27 de Abril de 1890 el Juzgado Administrativo, que quedó a cargo de José María Dupuy, quién de esa manera se transformó en la primera autoridad de Miramar. El Juez de Paz tenía a su cargo, en esa época, la administración municipal, judicial y policial de todo el pueblo, dependiendo de la Intendencia del Partido de General Pueyrredón, a la que pertenecían estas tierras (y del que era Intendente en ese momento Fortunato De La Plaza). La prensa escrita de Mar del Plata se hizo eco de las fiestas que se realizaron celebrando el acontecimiento.</p>
           <p className="text-start">A fines de 1889, se había iniciado la construcción de la Iglesia de Miramar, en un terreno cedido por Don Fortunato para tal fin. De La Plaza no sólo proporcionó el terreno, sino que además aportó los fondos necesarios para su construcción. A expreso pedido suyo, la iglesia recibió por titular al Apóstol San Andrés, en memoria de su padre.</p>
           <p className="text-start">La construcción, de estilo gótico y numerosos ventanales, presenta en la actualidad un aspecto ligeramente diferente al de sus orígenes, por cuanto a través de los años se</p>
           <p className="text-start">fueron efectuando algunas modificaciones, como la construcción de una nave lateral para darle mayor capacidad, el reemplazo del retablo de madera por un revestimiento de ladrillos, y el retiro del capitel que remataba la torre.</p>
           <p className="text-start">La iglesia se terminó de construir en abril de 1891, presentando una “arquitectura moderna que da majestad al edificio” (Diario “El Eco”, de Mar del Plata, 4 de mayo de 1890). Fue bendecida el 30 de noviembre de 1891, D. Luis Giachino.</p>
           <p className="text-start">Pero a la vez que organizaban el pueblo en sus aspectos administrativos, judiciales, comerciales y espirituales, los fundadores no olvidaban cuál era el propósito y a su vez el atractivo de Mira Mar: transformarse en una importante “estación de baños”. En tal sentido, De La Plaza, ya en 1889, realizó las primeras gestiones tendientes a lograr la instalación de un ramal del Ferrocarril Sud hasta el pueblo de Miramar y que hasta ese entonces llegaba sólo hasta Mar del Plata.</p>
           <p className="text-start">Ello se debe a que ese medio de transporte era prácticamente indispensable por aquellos años para atraer importantes cantidades de familias hacia nuestras costas. Pero infructuosos resultaron esos primeros intentos, debiendo esperarse 22 años hasta ver cumplido el anhelo de Fortunato de la Plaza.</p>
           <p className="text-start">Pero el Ferrocarril, por sí solo, no era suficiente para el tan ansiado despegue de Mira Mar como centro balneario: se precisaba además brindar facilidades de alojamiento a las familias, y ya en 1890, Miramar contaba con un Hotel: el “Argentino” (más tarde “Hotel Miramar”), ubicado en la esquina de las actuales calles 9 de Julio y 20 y del que las crónicas de la época hablan elogiosamente.</p>
           <p className="text-start">Vemos entonces que los fundadores de Miramar, ni bien lograron plasmar su propósito de fundación, se dedicaron con energía y esmero a dotar al pueblo de los elementos básicos para su crecimiento.</p>
           <p className="text-start">Pero ello no bastaba: Miramar, para su desarrollo sostenido, necesitaba de la autonomía administrativa y política del Partido de Gral. Pueyrredón. Es por ello que se iniciaron de inmediato las tramitaciones necesarias ante el gobierno de La Plata, tendientes a la formación de un nuevo Partido en el ámbito de la Provincia de Buenos Aires.</p>
           <p className="text-start">Para más información visite Museo Municipal “Punta Hermengo” de Miramar. www.museodemiramar.com.ar</p>
          </div>
        </div>
        <Footer />
        </div>
      </>
    );
  }
}

export default Historia;
