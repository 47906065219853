import React, { Component } from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
import icoGob from "../../images/Secretarias-Ico/01-secretaria-gobierno.svg";
import logo from "../../images/Logos/logo_a.png";

class Gobienrno extends Component {
  render() {
    return (
      <>
        <div className="App">
          <Nabvar />
          <div className="row Car-secretarias color-gobierno animacion  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <div className="col clearfix">
              <div className=" text-left color-blanco">
                <h1>Secretaría General y de Gobierno</h1>
                <img className="img-secret-logo " src={logo} />
              </div>
            </div>
            <div className="col">
              <img className="img-secret" src={icoGob} />
            </div>
          </div>
        
          
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Secretaria </h2>
            <h3> Belen Battenti</h3>
            <p >Municipalidad de Gral. Alvarado (Calle 28 No 1084 ) 3o piso </p>
            <p >(02291) 42-9000. gobierno@mga.gov.ar </p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Subsecretaria de Seguridad </h2>
            <h3> Jorge Daniel Núñez </h3>
            <p  >Avenida 40 y calle 15 – 1o piso Terminal de ómnibus – (02291) 42-1884 </p>
            <p  > seguridad@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Gestión de Recursos Humanos</h2>
            <h3>Claudia Moya</h3>
            <p  >Municipalidad de Gral. Alvarado (Calle 28 No 1084) PB – (02291) 42-9000 </p>
            <p  >personal@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Inspección General</h2>
            <h3>Pablo Giménez</h3>
            <p>Avenida 40 y calle 15 – 1o piso Terminal de ómnibus – (02291) 43-1076 </p>
            <p>inspeccion@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Tránsito y Nocturnidad</h2>
            <h3>José Félix Casas</h3>
            <p>Avenida 40 y calle 15 – 1o piso Terminal de ómnibus – (02291) 42-1884 </p>
            <p>seguridad@mga.gov.ar </p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Ceremonial y Protocolo</h2>
            <h3>Fabián Otegui</h3>
            <p>Municipalidad de Gral. Alvarado (Calle 28 No 1084) PB – (02291) 42-3510</p>
            <p>protocolo@mga.gov.ar</p>
          </div>
          
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Comunicación Social</h2>
            <h3>Natanael López</h3>
            <p  >Calle 28 N° 1084 3° Piso – Tel. (02291) 43-1241 / 43 (Conmutador Municipal) Interno </p>
            <p  >prensa@mga.gov.ar </p>
          </div>
        
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Relaciones con la Comunidad, ONG y Entidades</h2>
            <h3>Hernán López</h3>
            <p>Municipalidad de Gral. Alvarado (Calle 28 No 1084 ) PB – (02291) 42-9000 </p>
            <p>comunidad@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Regularización Dominial</h2>
            <h3>Yessica Martínez</h3>
            <p  > Municipalidad de Gral. Alvarado (Calle 28 No 1084) PB – (02291) 42-9000 int. 249</p>
            <p  > </p>
          </div>
        
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Delegación Comandante Nicanor Otamendi</h2>
            <h3>Miguel Ángel Rodríguez</h3>
            <p  > Belgrano 313 – (02291) 48-0012</p>
            <p  >otamendi@mga.gov.ar </p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Delegación Mechongué</h2>
            <h3>Cesar Candia</h3>
            <p  >Calle 9 esq. 12 – (02266) 49-3226 </p>
            <p  >mechongue@mga.gov.ar </p>
          </div>
   
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Delegación Mar del Sud</h2>
            <h3>Martín Heit</h3>
            <p  >Av. 100 e/ 23 y 25 – (02291) 49-1091 </p>
            <p  >delegacionmardelsur@mga.gov.ar </p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Conmutador / Mesa de Entradas Municipal</h2>
            <p  >Calle 28 N° 1084 PB – Tel. (02291) 43-1241 / 43-1245 </p>
           
          </div>

          <Footer />
        </div>
      </>
    );
  }
}

export default Gobienrno;
