import React, { Component } from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
import icodesarrollohumanos from "../../images/Secretarias-Ico/07-secretaria-desarrollo-humanos.svg";
import logo from "../../images/Logos/logo_a.png";
class DesarrolloHumano extends Component {
  render() {
    return (
      <>
        {" "}
        <div className="App">
          <Nabvar />
         
          <div className="row Car-secretarias color-desarrollo-humano animacion  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <div className="col text-left">
              <div className=" color-blanco ">
                <h1>Secretaría de Desarrollo Humano</h1>
                <img className="img-secret-logo" src={logo} />
              </div>
            </div>
            
            <div className="col">
              <img className="img-secret" src={icodesarrollohumanos} />
            </div>
          </div>

          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Secretaria </h2>
            <h3>Prof. María Josefina Bove</h3>
            <p>Calle 19 esq. 34 – (02291) 43-3201 </p>
            <p>desarrollohumano@mga.gov.ar </p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Tercera Edad y Discapacidad </h2>
            <h3>Tomás Besteiro.</h3>
            <p>Calle 34 y 19 – (02291) 43-3201 </p>
            <p>terceraedadydiscapacidad@mga.gov.ar </p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Niñez, Adolescencia y Juventud </h2>
            <h3>Dra. Andrea Rojas</h3>
            <p>CCalle 13 esq. 36 – (02291) 42-1083</p>
            <p>infancia@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Gestión Social </h2>
            <h3>Coordinadora: Susana Espiño</h3>
            <p>Calle 19 esq. 34 – (02291) 43-3201</p>
            <p>serviciosocial@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Infraestructura Social </h2>
            <h3>Willy Álvarez</h3>
            <p>Calle 19 esq. 34 – (02291) 43-3201 </p>
            <p>desarrollohumano@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Jefatura de Tercera Edad</h2>
            <h3>Karina Fernández.</h3>
            <p>Calle 13 esq. 36 – (02291) 43-4161 </p>
            <p>terceraedadydiscapacidad@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Coordinación de la Economía Social </h2>
            <h3>Verónica Alimonta</h3>
            <p>Calle 19 esq. 34 – (02291) 43-3201</p>
            <p></p>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default DesarrolloHumano;
