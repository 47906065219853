
import React, { Component } from 'react';
import Footer from '../../components/Footer';

class Cv extends Component {
    
      
  
    render() {
      return (
         <>
        
         <iframe id="CV-trayectoria-laborales"
           title="CV-trayectoria-laborales"
           width="100%"
           height="800px"
           scrolling="yes"
           src="http://cv.mgaservicios.online/" strict-origin-when-cross-origin>
       </iframe>
        <Footer/>
         </>
      );
    }

    
    }
    
    export default Cv;