
import React, { Component} from 'react';
import Footer from '../../components/Footer';

class ConsultaDeuda extends Component {
    
      
  
    render() {
      return (
         <>
        
         <iframe id="inlineFrameExample"
           title="Inline Frame Example"
           width="100%"
           height="800px"
           scrolling="yes"
           src="https://miram.ar/consulta/" strict-origin-when-cross-origin>
       </iframe>
        <Footer/>
         </>
      );
    }

    
    }
    
    export default ConsultaDeuda;