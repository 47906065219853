import React, { Component } from "react";

import Slider from "infinite-react-carousel";
import axios from "axios";

class Slidershow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticias: [],
    };
  }

  componentDidMount() {
    axios
      .get(`https://mgaservicios.online/api_prensa/v1/vernoticias?page=1`)
      .then((res) => {
        const noticias = res.data;
        console.log(noticias);
        this.setState({ noticias });

      }).catch(error=>{
        console.log(error);
      });
  }



  render() {


    const slideImages = [
      "https://mga.gov.ar/wp-content/uploads/2022/07/MGA-Castraciones-Masivas-CIC-Aeroparque-06-Agosto-2022-wide-940x280.jpg",
      "https://mga.gov.ar/wp-content/uploads/2022/07/MGA-Castraciones-Masivas-CIC-Aeroparque-06-Agosto-2022-wide-940x280.jpg",
      "https://mga.gov.ar/wp-content/uploads/2022/07/MGA-Castraciones-Masivas-CIC-Aeroparque-06-Agosto-2022-wide-940x280.jpg",
      "https://mga.gov.ar/wp-content/uploads/2022/07/MGA-Castraciones-Masivas-CIC-Aeroparque-06-Agosto-2022-wide-940x280.jpg",
      "https://mga.gov.ar/wp-content/uploads/2022/07/MGA-Castraciones-Masivas-CIC-Aeroparque-06-Agosto-2022-wide-940x280.jpg",
      "https://mga.gov.ar/wp-content/uploads/2022/07/MGA-Castraciones-Masivas-CIC-Aeroparque-06-Agosto-2022-wide-940x280.jpg",
      "https://mga.gov.ar/wp-content/uploads/2022/07/MGA-Castraciones-Masivas-CIC-Aeroparque-06-Agosto-2022-wide-940x280.jpg",
      
    ];
    const slideTitulo = [];
    const slideLink = [];
    

    const settings = {
      autoplay: true,
      autoplaySpeed: 5000,
      className: "slide_box",
      dots: true,
      virtualList: true,
      duration: 20,
      arrows: true,
    };
    return (
      <div id="slide_box" className="rounded">
        <Slider {...settings}>
        {this.state.noticias && 
           this.state.noticias.map((noticia)=>(
        
         <div key={noticia.NoticiaId}>
         <img
           src={slideImages[0]}
           className="slide_img col-12"
           alt="Responsive img"
         />
        <div className="silder-titulo">
         <div>{noticia.Titulo}</div>
         <a href={noticia.Imagen} >ver nota </a></div>
       </div>
       ))}
     
          <div>
            <img
              src={slideImages[3]}
              className="slide_img col-12"
              alt="Responsive img"
            />
             <div className="silder-titulo">
             <div>{slideTitulo[3]}</div>
              <a href={slideLink[3]}>ver nota </a></div>
          </div>
          <div>
            <img
              src={slideImages[4]}
              className="slide_img col-12"
              alt="Responsive img"
            />
             <div className="silder-titulo">
             <div>{slideTitulo[4]}</div>
              <a href={slideLink[4]}>ver nota </a></div>
          </div>
          
         
    
        </Slider>
      </div>
    );
  }
}
export default Slidershow;
