import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import UsoInterno from "./Pages/UsoInterno";
import Gobierno from "./Pages/Gobierno";
import ObrasPublicas from "./Pages/ObrasPublicas";
import Salud from "./Pages/Salud";
import Noticias from "./Pages/Noticias";
import Alerta from "./components/Alert";
import AccesoApoderadoExterno from "./Pages/AccesoApoderadoExterno";
import AccesoHaberes from "./Pages/AccesoHaberes";
import MenuConexionCloacas from "./Pages/MenuConexionCloacas";
import ConexionDeCloacas from "./Pages/ConexionDeCloacas";
import ExtencionDeCloacas from "./Pages/ExtencionDeCloacas";
import MenuConexionAgua from "./Pages/MenuConexionAgua";
import ConexionDeAgua from "./Pages/ConexionDeAgua";
import ExtencionDeAgua from "./Pages/ExtencionDeAgua";
import Turismo from "./Pages/Turismo";
import ExpoEducativa from "./Pages/ExpoEducativa";
import DesarrolloHumano from "./Pages/DesarrolloHumano";
import DerechoHumano from "./Pages/DerechoHumano";
import Economia from "./Pages/Economia";
import ConsultaDeuda from "./Pages/ConsultaDeuda";
import DatosAbiertos from "./Pages/DatosAbiertos";
import Historia from "./Pages/Historia";
import FiestaRegional from "./Pages/FiestaRegional";
import LiquidacionWEB from "./Pages/Liquidacion-WEB";
import Luminarias from "./Pages/Luminarias";
import DefensaConsumidor from "./Pages/DefensaConsumidor";
import HabilitacionesComerciales from "./Pages/HabilitacionesComerciales";
import PresentacionDePlanos from "./Pages/PresentacionDePlanos";
import SolicitudInspeccion from "./Pages/SolicitudInspeccion";
import ParqueIndustrial from "./Pages/ParqueIndustrial";
import ExtraccionDeArbol from "./Pages/ExtraccionDeArbol";
import Comunidad from "./Pages/Comunidad";
import MapaDeObrasPublicas from "./Pages/MapaDeObrasPublicas";
import Hcd from "./Pages/Hcd";
import JuzgadodeFaltas from "./Pages/JuzgadodeFaltas";
import BoletinOficial from "./Pages/BoletinOficial";
import MenuSem from "./Pages/MenuSem";
import SemI from "./Pages/SemInfraccions";
import SemApp from "./Pages/SemApp";
import Produccion from "./Pages/Produccion";
import Intendencia from "./Pages/Intendencia";
import Localidades from "./Pages/Localidades";
import Planification from "./Pages/Planificacion";
import LicenciaDeConducir from "./Pages/LicenciaDeConducir";
import Agenda from "./Pages/Agenda";
import Cv from "./Pages/Cv";
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/CV-trayectoria-laborales" element={<Cv />} />
          <Route path="/Agenda" element={<Agenda />} />
          <Route path="/Licencia-De-Conducir" element={<LicenciaDeConducir />} />
          <Route path="/Planificacion-Obras-y-Servicios" element={<Planification />} />
          <Route path="/semappweb" element={<SemApp />} />
          <Route path="/Localidades" element={<Localidades/>} />
          <Route path="/Intendencia" element={<Intendencia/>} />
          <Route path="/Produccion-Emple-OMIC-y-Medio-Amb" element={<Produccion />} />
          <Route path="/sem-intracciones" element={<SemI />} />
      
          <Route path="/Obras-Publicas" element={<ObrasPublicas />} />
          <Route path="/SEM" element={<MenuSem />} />
          <Route path="/Comunidad" element={<Comunidad />} />
          <Route path="/Extraccion-De-Arbol" element={<ExtraccionDeArbol />} />
          <Route
            path="/Radicacion-de-Industria"
            element={<ParqueIndustrial />}
          />
          <Route
            path="/Presentacion-De-Planos"
            element={<PresentacionDePlanos />}
          />
          <Route
            path="/Solicitud-Inspeccion"
            element={<SolicitudInspeccion />}
          />
          <Route
            path="/Habitaciones-Comerciales"
            element={<HabilitacionesComerciales />}
          />
          <Route
            path="/Defensa-del-Consumidor"
            element={<DefensaConsumidor />}
          />
          <Route path="/Consultar-Deuda" element={<ConsultaDeuda />} />
          <Route
            path="/AccesoApoderadoExterno"
            element={<AccesoApoderadoExterno />}
          />
          <Route path="/AccesoHaberes" element={<AccesoHaberes />} />
          <Route path="/Alerta" element={<Alerta />} />
          <Route path="/Uso-interno" element={<UsoInterno />} />
          <Route path="/Gobierno" element={<Gobierno />} />
          <Route
            path="/Mapa-Obras-Publicas"
            element={<MapaDeObrasPublicas />}
          />
          <Route path="/Salud" element={<Salud />} />
          <Route path="/Desarrollo-Humano" element={<DesarrolloHumano />} />
          <Route path="/Turismo-Cultura-y-Deporte" element={<Turismo />} />
          <Route path="/Derechos-Humanos" element={<DerechoHumano />} />
          <Route path="/Economia" element={<Economia />} />
          <Route path="/HCD" element={<Hcd />} />
          <Route path="/Juzgado-de-Faltas" element={<JuzgadodeFaltas />} />
          <Route path="/Boletin-Oficial-de-Derechos" element={<Salud />} />
          <Route path="/Liquidacion-WEB" element={<LiquidacionWEB />} />
          <Route path="/Calendario-de-Vencimientos" element={<Salud />} />
          <Route path="/Noticias" element={<Noticias />} />
          <Route path="/Boletin-Oficial" element={<BoletinOficial />} />
          <Route path="/Expo-Edicativa" element={<ExpoEducativa />} />
          <Route
            path="/Menu-Conexion-Cloacas"
            element={<MenuConexionCloacas />}
          />
          <Route
            path="/Extencion-De-Cloacas"
            element={<ExtencionDeCloacas />}
          />
          <Route path="/Conexion-De-Cloacas" element={<ConexionDeCloacas />} />
          <Route path="/Menu-Conexion-Agua" element={<MenuConexionAgua />} />
          <Route path="/Extencion-De-Agua" element={<ExtencionDeAgua />} />
          <Route path="/Conexion-De-Agua" element={<ConexionDeAgua />} />
          <Route path="/Datos-Abiertos-Beta" element={<DatosAbiertos />} />
          <Route path="/Historia" element={<Historia />} />
          <Route path="/Fiestas-Regionales" element={<FiestaRegional />} />
          <Route path="/Luminarias" element={<Luminarias />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
