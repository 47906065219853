import React, { Component} from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
import Checkbox from "../../images/iconos/checkbox-outline.svg";

class LicenciaDeConducir extends Component {
  render() {
    return (
      <>
        <div className="App">
          <Nabvar />
          <div className="row  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <h1>Requisitos para la obtención de la Licencia de Conducir</h1>
          </div>

          <div className="row">
            <div className="col rounded-4 shadow-lg   pb-5 pt-5 mt-5">
              <h2></h2>
              <p className="text-start img-p-h-c ">
                <img src={Checkbox} /> Presentar D.N.I., LC. o L.E. con el
                último domicilio asentado dentro del Partido de Gral. Alvarado.
              </p>
              <p className="text-start img-p-h-c">
                <img src={Checkbox} />
                Los ciudadanos de 16 y 17 años que deseen obtener su licencia de
                conducir deben acudir acompañados con una autorización realizada
                en el Juzgado de Paz o ante escribano, firmada por sus padres y
                constancia de factor sanguíneo.
              </p>
              <p className="text-start img-p-h-c">
                <img src={Checkbox} />
                Los ciudadanos mayores de 18 años que realicen por primera vez
                su licencia de conducir deberán traer la constancia de su
                factor.
              </p>
              <p className="text-start img-p-h-c">
                <img src={Checkbox} />
                Los ciudadanos que deseen realizar una renovación, un cambio de
                domicilio o una ampliación deberán presentarse con su D.N.I y su
                licencia de conducir para poder iniciar el trámite.
              </p>
              <p className="text-start img-p-h-c">
                <img src={Checkbox} /> Aquellos ciudadanos que deseen realizar
                una ampliación para obtener una categoría profesional, deberán
                tener como mínimo un año de antigüedad en su licencia, ser mayor
                de 21 años, y adjuntar el certificado de antecedentes penales,
                sumándole el D.N.I y la licencia de conducir en vigencia.
              </p>
              <p className="text-start img-p-h-c">
                <img src={Checkbox} />
                El ciudadano que necesite realizar un duplicado de su licencia
                por perdida de la misma debe presentarse en esta dependencia con
                un certificado de extravío que realizara en el registro civil,
                si la licencia le fue robada deberá presentar la denuncia
                policial en original y fotocopia.
              </p>
              <p className="text-start img-p-h-c">
                <img src={Checkbox} />
                Las renovaciones se realizan dentro de los treinta días antes de
                vencer la licencia de conducir y de esta forma sigue habilitado
                para conducir, una vez vencida tiene 90 días para renovar, los
                cuales no habilitan para conducir.
              </p>
              <p className="text-start img-p-h-c">
                <img src={Checkbox} />
                El ciudadano que presente una licencia de conducir de otra
                jurisdicción, deberá presentar un certificado de legalidad para
                realizar el trámite que requiera, el mismo le será entregado en
                el municipio que le expidió su licencia.
              </p>
              <p className="text-start img-p-h-c">
                <img src={Checkbox} />
                Inicio de trámite: pagos de aranceles nacionales, provinciales y
                municipales; digitalización de datos, entregando formularios
                para los exámenes médicos y exámenes teóricos prácticos.
              </p>
            </div>

            <div className="row  animacion  shadow-lg border border-3 rounded-4 border-danger mt-4">
              <h3 className=" text-danger  ">
                ***SR. CONDUCTOR UNA VEZ VENCIDA SU LICENCIA NO ESTA HABILITADO
                PARA CONDUCIR***
              </h3>
            </div>

            <div className="row  animacion rounded-3 border border-3 rounded-4 border-success shadow-lg mt-4 ">
              <h2>Dirección de Tránsito y Nocturnidad</h2>

              <p>
                Avenida 40 y calle 15 – 1o piso Terminal de ómnibus – (02291)
                42-1884{" "}
              </p>
              <p>seguridad@mga.gov.ar </p>
            </div>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}

export default LicenciaDeConducir;
