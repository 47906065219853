import React, { Component } from 'react';
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';
class DatosAbiertos extends Component {
    
      
  
    render() {
      return (
   <>
   <div className="App">
   <Nabvar/>

   
   <Footer/>
   </div>

  </>
      );
    }

    
    }
    
    export default DatosAbiertos;