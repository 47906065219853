import React, { Component } from 'react';
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';
import produccion from "../../images/Secretarias-Ico/04-secretaria-produccion.svg";
import logo from "../../images/Logos/logo_a.png";
class Produccion extends Component {
    
      
  
    render() {
      return (
   <>
 <div className="App">
          <Nabvar />
     
          <div className="row Car-secretarias color-produccion animacion  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <div className="col text-left">
              <div className=" color-blanco ">
                <h1>Secretaría de Producción, Desarrollo Local, Empleo y OMIC</h1>
                <img className="img-secret-logo" src={logo} />
              </div>
            </div>
            
            <div className="col">
              <img className="img-secret" src={produccion} />
            </div>
          </div>

          <div className="row animacion  shadow-lg  Car-secondary-1">
            <h2>Secretario </h2>
            <h3>Téc. Federico Meaca</h3>
            <p>Calle 21 No 1617 of. 2 – (02291) 43-0534</p>
            <p>producción@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg  Car-secondary-1">
            <h2>Dirección de Desarrollo Local y Empleo</h2>
            <h3>María Laura Banús</h3>
            <p>Calle: 28 no 1135 Tel: (02291) 42-1482</p>
            <p>empleo@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg  Car-secondary-1">
            <h2>OMIC - Oficina de información al Consumidor</h2>
            <h3>Dr. Pablo Otamendi</h3>
            <p>Calle: 28 no 1135 – Tel: (02291) 42-3239</p>
            <p>omic@mga.gov.ar</p>
          </div>
          <div className="row animacion  shadow-lg  Car-secondary-1">
            <h2>Dirección de Gestión Ambiental</h2>
            <h3>Lic. Romina Castro</h3>
            <p>Calle 21 N° 1617 1° Piso – (02291) 43-0534</p>
            <p>medioambiente@mga.gov.ar</p>
          </div>


   <Footer/>
   </div>
  </>
      );
    }

    
    }
    
    export default Produccion;