import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./mapa.css";
import "ol/ol.css";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
class MapaDeObrasPublicas extends Component {
  render() {
    return (
      <>
      <div className="App">
        <Nabvar />
      
        <Footer />
        </div>
      </>
    );
  }
}

export default MapaDeObrasPublicas;
