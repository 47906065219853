import React, { Component } from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
import IconoEcomomia from "../../images/Secretarias-Ico/02-secretaria-de-economia.svg";
import logo from "../../images/Logos/logo_a.png";
class Economia extends Component {
  render() {
    return (
      <>
        <div className="App">
          <Nabvar />
         
          <div className="row Car-secretarias color-de-derechos-humanos animacion  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <div className="col text-left">
              <div className=" color-blanco ">
                <h1>Secretaría de Economía</h1>
                <img className="img-secret-logo" src={logo} />
              </div>
            </div>
            
            <div className="col">
              <img className="img-secret" src={IconoEcomomia} />
            </div>
          </div>

          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Secretario </h2>
            <h3> C.P.N. Pablo Ramajo</h3>
            <p>Municipalidad de Gral. Alvarado (Calle 28 No 1084) 1o Piso – (02291) 42-9000</p>
            <p>economia@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Catastro y Gestión Territorial </h2>
            <h3> </h3>
            <p>Municipalidad de Gral. Alvarado (Calle 28 No 1084) 2o Piso – (02291) 42-9000</p>
            <p>catastro@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Apremios Judiciales </h2>
            <h3> </h3>
            <p>Municipalidad de Gral. Alvarado (Calle 28 No 1084) –Subs. – (02291) 42-9000</p>
            <p>apremios@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Ingresos Públicos</h2>
            <h3>Florencia Aulita</h3>
            <p>Municipalidad de Gral. Alvarado (Calle 28 No 1084) – 1° Piso (02291) 42-9000 / 432526 (directo)</p>
            <p>rentas@mga.gov.ar</p>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default Economia;
