import React, { Component } from "react";
import { Link } from "react-router-dom";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
import Mapasem from "../../components/MapaSem";
import SemlogoAndroid from "../../images/sem/semalvaradoANDROID-225x300.jpeg";
import SemlogoIos from "../../images/sem/semalvaradoIOS-225x300.jpeg";
import SemPuntoVenta from "../../images/sem/mapasem-v5-1-768x609.jpeg";
import icoGob from "../../images/Secretarias-Ico/01-secretaria-gobierno.svg";
import logo from "../../images/Logos/logo_a.png";


class MenuSem extends Component {
  render() {
    return (
      <>
        <div class="App">
          <Nabvar />

          <div class=" ">
          <div className="row Car-secretarias color-gobierno animacion  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <div className="col clearfix">
              <div className=" text-left color-blanco">
                <h1>SEM MGA</h1>
                <img className="img-secret-logo " src={logo} />
              </div>
            </div>
            <div className="col">
              <img className="img-secret" src={icoGob} />
            </div>
          </div>


          <div className="row mt-5">

            <ul>
              <li>
                <a href="#preguntas" className="button-Noticia animacion">
                  Click Aqui para saber mas
                </a>
              </li>
              <li></li>
              <li>
                <Link to="/sem-intracciones">
                  <button className="button-Noticia animacion">
                    ¿Tengo Infracciones? Click Aqui
                  </button>
                </Link>
              </li>
              <li></li>
              <li>
                <Link to="/semappweb">
                  <button className="button-Noticia animacion">
                    Gestione tu estacionamiento sin Utilizar la App Movil Click
                    aqui
                  </button>
                </Link>
              </li>
            </ul>
            </div>

          </div>

          <div className="row ">
            <h2>Información sobre los métodos de pago</h2>
            <h3 className="text-start">Sin Teléfono Celular:</h3>
            <p className="text-start">
              *Estacione su vehículo. El mismo tendrá una tolerancia de 15
              minutos para realizar el pago del estacionamiento.
            </p>
            <p className="text-start">
              * Diríjase a un PUNTO DE VENTA, solicite realizar un
              ESTACIONAMIENTO PUNTUAL, indique su patente y tiempo de
              permanencia.
            </p>
            <p className="text-start">
              *El Comercio entregará un comprobante con la hora límite y la
              patente correspondiente, el mismo no hace falta dejarlo en su
              vehículo ya que los inspectores controlan la autorización por
              sistema.
            </p>
          </div>

          <div className="row pt-4 pb-4">
            <h2>PUNTOS DE VENTA</h2>
            <div className="col-12 img-sem-m ">
              <img src={SemPuntoVenta} />
            </div>
          </div>
          <div className="row pt-4 pb-4">
            <h2>Desde la web</h2>
            <div className="pt-4">
              <Link to="/semappweb">
                <button className="button-Noticia animacion">
                  http://miramar.dat.cespi.unlp.edu.ar
                </button>
              </Link>
            </div>
            <div className="pt-4">
              <Link to="https://play.google.com">
                <button className="button-Noticia animacion">
                  A través de la app SEM Miramar https://play.google.com
                </button>
              </Link>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-6 img-sem animacion">
              <img src={SemlogoAndroid} />
            </div>
            <div className="col-6 img-sem animacion">
              <img src={SemlogoIos} />
            </div>
          </div>

          <div className="row pt-4">
            <h2 className="text-start">
              INSTRUCTIVO DE LA APP PARA ESTACIONAR
            </h2>
            <p className="text-start">
              Abra la aplicación, seleccione el menú Estacionamiento y haga
              click sobre el botón «Iniciar Estacionamiento «. Ingrese su
              patente sin espacios.
            </p>
            <div className="col-12">
              <div className="embed-responsive embed-responsive-21by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/MAnajsF3nzA"
                  frameborder="0"
                  allowfullscreen
                  width="560"
                  height="315"
                ></iframe>
              </div>
            </div>
            <h2 className="text-start">PARA FINALIZAR ESTACIONAMIENTO</h2>
            <p className="text-start">
              Abra la aplicación, seleccione el menú Estacionamiento y haga
              click sobre el botón «Finalizar Estacionamiento «.
            </p>
            <h2 className="text-start">OTRAS OPCIONES</h2>
            <p className="text-start">
              Desde la aplicación, también podrá consultar su saldo actualizado,
              la zona de estacionamiento, la ocupación de las calles de
              estacionamiento medido para poder elegir calles que podrían tener
              lugares libres, los puntos de venta adheridos geolocalizados,
              realizar transferencias de crédito entre usuarios de SEM, pagar
              infracciones por pago voluntario con tarjeta de crédito y/o débito
              entre otras.
            </p>
            <h2 className="text-start">Inspección del estacionamiento</h2>
            <p className="text-start">
              Inspectores de tránsito de la ciudad, equipados con dispositivos
              móviles online, recorren la zona de estacionamiento medido
              verificando instantáneamente si cada vehículo tiene el
              estacionamiento medido activo.
            </p>
            <p className="text-start">
              Si el vehículo no está autorizado por el sistema, tendrá 15
              minutos de tolerancia para activarlo a su patente.
            </p>
            <p className="text-start">
              Pasados esos 15 minutos y de continuar sin activarlo, el inspector
              procederá a labrar el acta correspondiente, quedando la
              comprobación de la infracción registrada en el sistema y un
              comprobante de la infracción en el vehículo.
            </p>
          </div>
          <div className="row">
            <h2>MAPA ZONAS DE ESTACIONAMIENTO MEDIDO </h2>
          </div>

          <Mapasem />
          <div className="row pt-4">
            <h2 className="text-start" id="preguntas">
              Preguntas frecuentes Estacionamiento Medido
            </h2>
            <h4 className="text-start">
              1) ¿A partir de qué día comienza el Estacionamiento Medido? ¿Cuál
              es el horario comprendido?
            </h4>
            <p className="text-start">
              El Sistema de Estacionamiento Medido (S.E.M.), estará disponible
              en la ciudad, a partir del 15 de diciembre de 2021 hasta el día 15
              de marzo de 2022. El horario de funcionamiento del sistema es de
              08:00hs a 23:00hs de lunes a lunes.
            </p>
            <h4 className="text-start"> 2) ¿Cuál es el costo?</h4>
            <p className="text-start">
              El costo es de $30 la hora (Carga mínima es de una Hora tanto en
              App como en Punto de Venta).
            </p>
            <h4 className="text-start">
              3) No puedo ingresar, me indica USUARIO/CONTRASEÑA incorrecta
            </h4>
            <p className="text-start">
              Un error muy común, es intentar ingresar con el email en usuario,
              recuerde que esto es erróneo, su USUARIO es SU NUMERO TELEFONICO,
              la CONTRASEÑA de primer ingreso es el código recibido por email.
              En caso de aun no poder ingresar, recuerde que su usuario es su
              número telefónico tal cual lo ingreso en el registro, por ejemplo:
              Si usted coloco el 0 y el 15, deberá hacerlo para iniciar sesión,
              en caso que no lo haya colocado, no deberá ingresarlos para
              iniciar sesión. Su usuario es tal cual lo registro. En caso de no
              poder solucionarlo, envié un WhatsApp a 2291400000 o 2291404444
              indicando su email y número telefónico para poder asesorarlo.
            </p>
            <h4 className="text-start">
             
              4) ¿Los residentes de todo el año están afectados a este servicio?{" "}
            </h4>
            <p className="text-start">
              Residentes de todo el año en la zona afectada por el S.E.M, podrán
              registrarse en el formulari cumpliendo los siguientes requisitos:
            </p>

            <p className="text-start">
             
              a) Ser Residente de Miramar y Tener el DNI con el domicilio en
              alguna de las calles del estacionamiento medido{" "}
            </p>

            <p className="text-start">
              b) Tener un inmueble con la dirección de puerta en alguna de las
              calles del estacionamiento residiendo por más de un año en la
              ciudad o tener un contrato de alquiler FORMAL de dos años.
            </p>

            <p className="text-start">
              c) Iniciar el trámite en la oficina de relaciones con la
              comunidad, planta baja de la Municipalidad. Lunes a viernes de
              08:00hs a 13:00hs.
            </p>
            <h4 className="text-start">
          
              5) ¿Qué sucede con las personas con oblea de discapacidad?
            </h4>
            <p className="text-start">
              Las personas que posean su oblea de discapacidad vigente, quedarán
              desafectados del estacionamiento medido en la ciudad.
            </p>
            <h4 className="text-start">
       
              6) ¿Afecta a las zonas de carga y descarga?
            </h4>
            <p className="text-start">
              El sistema no opera en zona de carga y descarga, Ascenso y
              Descenso de Pasajeros, y lugares de reserva con línea amarilla
              reglamentaria y habilitación municipal.
            </p>
            <h4 className="text-start">
              7) ¿Cómo se va a llevar adelante el operativo?
            </h4>
            <p className="text-start">
              Los Controladores S.E.M., equipados con dispositivos móviles
              online, recorren la zona de estacionamiento medido verificando
              instantáneamente si cada vehículo tiene el estacionamiento medido
              activo. Si el vehículo no está autorizado por el sistema, tendrá
              15 minutos de tolerancia para activarlo a su dominio. Pasados esos
              15 minutos y de continuar sin activación, el inspector procederá a
              labrar el acta correspondiente, quedando la comprobación de la
              infracción registrada en el sistema y un comprobante de la
              infracción en el vehículo.
            </p>
            <h4 className="text-start">
              8) ¿Si se me acaba el crédito, debo volver a iniciar
              estacionamiento?
            </h4>
            <p className="text-start">
              Si su crédito finaliza mientras tiene estacionamiento activo, el
              mismo no se detendrá. Seguirá debitando dejando su cuenta en
              número negativo. Con esto usted tendrá la tranquilidad de dejar su
              vehículo, sin preocuparse de que el estacionamiento se finalice y
              quede en infracción. Luego deberá realizar una nueva carga de
              crédito para saldar la deuda pendiente.
            </p>
            <h4 className="text-start">
              9) ¿Puedo dejar activo mi estacionamiento para que se mantenga al
              otro día?
            </h4>
            <p className="text-start">
              Si, si usted activa su estacionamiento después de las 00:00hs, el
              mismo se activará automáticamente al otro día después de las
              08:00hs. También contamos con la web de gestión, donde puede
              realizar estacionamientos programados, y que inicie toda la semana
              a las 08:00hs.
              <div className="pl-4 pt-2">
                <Link to="/semappweb">
                  <button className="button-Noticia animacion">Web AQUÍ.</button>
                </Link>
              </div>
            </p>

            <h4 className="text-start text-danger animacion"> Incumplimiento:</h4>
            <p className="text-start">
              Se da a partir de los 15 minutos desde que pasó el controlador y
              no se registra Estacionamiento Activo (pago), esto da lugar a una
              infracción.
            </p>
            <h4 className="text-start text-danger animacion">Una vez infraccionado: </h4>
            <h5 className="text-start text-success">
              a) Pago Voluntario en punto de venta adherido.
            </h5>
            <p className="text-start">
              La infracción por incumplimiento cuenta con un pago voluntario de
              $300 dentro de las 48hs de ser realizada la infracción. Puede
              abonar este pago en nuestros puntos de venta adheridos o a través
              de la App SEM Miramar en Android o SEM Mobile en iOS.{" "}
            </p>
            <h5 className="text-start text-success">
              b) Luego de las 3 horas de que el vehículo ha sido infraccionado
            </h5>
            <p className="text-start">
              Será removido del lugar por una grúa si aún no fue retirado o no
              abono el estacionamiento correspondiente.
            </p>
            <h5 className="text-start text-success">
              c) Luego de 48 hs. La infraccion pasa a manos del juzgado de
              Faltas local.
            </h5>
            <p className="text-start">
             
              Luego de pasar las 48hs. De su infracción, perderá el beneficio
              del pago voluntario y deberá abonar el 100% de la misma en el
              Juzgado de Faltas de la ciudad.
            </p>
          </div>
          <div className="text-start animacion">
            <h1 className="text-start">Oficina de S.E.M</h1>
            <p>
              Av. 40 N°715 (Terminal de Ómnibus) – 1° Piso. Email:
              estacionamientomedidomga@gmail.com
            </p>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}

export default MenuSem;
