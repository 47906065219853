import React from "react";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

const Menu = () => {
  return (
    <>



<div className="menu shadow-lg animacion">
    <div className=" bunner-fc">
      <div className="triangulo"></div>
      <div className="bunner-fc-a"><div className="mx-auto p-2">PAGAR LAS TASAS NUNCA FUE TAN FACIL</div></div>
      <div className="bunner-fc-b animacion-2">1</div>
      <div className="bunner-fc-c"><div className="mx-auto p-2">Registrate en la web</div></div>
      <div className="bunner-fc-d m-2 animacion-2">2</div>
      <div className="bunner-fc-e"><div className="mx-auto p-2">Recibí la FC al mail</div></div>
      <div className="bunner-fc-f m-2 animacion-2">3</div>
        <div className="bunner-fc-g"><div className="mx-auto p-2">Abona como quieras</div></div> 
    </div>
</div>

</>
  );
};
export default Menu;