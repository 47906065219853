import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';
import Checkbox from "../../images/iconos/checkbox-outline.svg";
class MenuConexionCloacas extends Component {
    
    render() {
   
      return (
   <>
   <div class="App">
          <Nabvar />
          <div className="row m-2">
            <h1>Dirección de Obras Sanitarias</h1>

            <h3>Documentación necesaria para realizar el trámite</h3>
          </div>

          <div className="row Car-secondary-1">
            <h2>Conexion de Cloacas</h2>

            <p className="text-start img-p-h-c">
              <img src={Checkbox} /> ---------------
            </p>

            <ul>
            <li><Link to="/Conexion-De-Cloacas"><button className="button-Noticia">Conexion De Cloacas</button></Link></li>
       
            </ul>
          </div>
          <div className="row Car-secondary-1">
            <h2>Extencion de Cloacas</h2>

            <p className="text-start img-p-h-c">
              <img src={Checkbox} /> ---------------
            </p>

            <ul>
            <li><Link to="/Extencion-De-Cloacas"><button className="button-Noticia">ExtencionesDeCloacas</button></Link></li>

            </ul>
          </div>

          <Footer />
        </div>
  
   
        
 
  
  </>
      );
    }

    
    }
    
    export default MenuConexionCloacas;