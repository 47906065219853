import React, { Component } from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
import icosalud from "../../images/Secretarias-Ico/03-secretaria-salud.svg";
import logo from "../../images/Logos/logo_a.png";
class Salud extends Component {
  render() {
    return (
      <>
        <div className="App">
          <Nabvar />
          <div className="row Car-secretarias color-salud animacion  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <div className="col text-left">
              <div className=" color-blanco ">
                <h1>Secretaría de Salud</h1>
                <img className="img-secret-logo" src={logo} />
              </div>
            </div>
            
            <div className="col">
              <img className="img-secret" src={icosalud} />
            </div>
          </div>

          <div className="row  animacion  shadow-lg Car-secondary-1">
            <h2>Dirección Administrativa del Hospital Dr. Marino Casano </h2>
            <h3>Matías Carrazana</h3>
            <p>José María Dupuy No 1550 – (02291) 43-1253</p>
            <p>hospital@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg Car-secondary-1">
            <h2>Dirección de Atención Primaria de la Salud </h2>
            <h3> Dra. Patricia Reyes</h3>
            <p>Calle 57 e/24 y 26 – (02291) 42-1993</p>
            <p>aps@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg Car-secondary-1">
            <h2>Director del Hospital Municipal "Dr. Marino Cassano" </h2>
            <h3> Dr. Alberto Odzak</h3>
            <p>Diag. José María Dupuy No 1550 – (02291) 42-4129</p>
            <p>salud@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg Car-secondary-1">
            <h2>Dirección de Salud Mental </h2>
            <h3> Lic. Juan Luis Lovari</h3>
            <p>José María Dupuy No 1550</p>
            <p>salud@mga.gov.ar</p>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}

export default Salud;
