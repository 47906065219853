import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import MapaObrasPublicas from "../../components/MapaObrasPublicas";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
class ObrasPublicas extends Component {
  render() {
    return (
      <>
        <div className="App">
          <Nabvar />
          
          <MapaObrasPublicas/>
          
          <Footer />
        </div>
      </>
    );
  }
}

export default ObrasPublicas;
