import React, { Component } from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
import Checkbox from "../../images/iconos/checkbox-outline.svg";
class PresentacionDePlanos extends Component {
  render() {
    return (
      <>
        <div className="App">
          <Nabvar />
          <div className="row  animacion  shadow-lg m-2">
            <h1>Secretaría de Producción </h1>
            <h2>Radicación de industria en el Parque Industrial</h2>
            <h3>
              Documentación necesaria para realizar el trámite 
            </h3>
          </div>
          <div className="row  animacion  shadow-lg p-3">
            <p className="text-start img-p-h-c">
             
              <img src={Checkbox} /> ---------------
            </p>
           
          </div>
          <div className="row  animacion  shadow-lg p-5">
            <a href="#">
              <button className="button-Noticia">
                Iniciar Solicitud tramite Click Aqui
              </button>
            </a>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default PresentacionDePlanos;
