import React, { Component } from 'react';
import Nabvar from '../components/Nabvar';
import Menu from '../components/Menu';
import MenuDos from '../components/MenuDos';
import MenuTres from '../components/MenuTres';
import Footer from '../components/Footer';
import './App.css';
import { Notifications } from 'react-push-notification';
import Slidershow from '../components/Slidershow';
class Home extends Component {
   
  
    render() {
      return (
      
        <div className="App">
          <Notifications />
         <Nabvar />
         <Slidershow />
         <Menu />
         <MenuDos />
         <MenuTres /> 
         <Footer />
        </div>
   
      );
    }

   
    }
    
    export default Home;