import React from "react";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import comunidad from "../../images/Menu/comunidad.svg";
import mapa from "../../images/Menu/mapa.svg";
import sem from "../../images/Menu/iconos-sem.svg";
const MenuDos = () => {
  return (
    <>
      <div id="ATENCION-AL-VECINO" >
        <div id="cent" className="row ">
          <div id="MenuDos" className="card call-center shadow-lg animacion ">
          <h2 className="card-title text-light pt-4">COMUNIDAD</h2>
            <a href="Comunidad" className=" car-hover-a">
              <img
                src={comunidad}
                className="card-img-top-1"
                alt="Relaciones con la comunidad"
              />
            </a>
            <div className="linea-f">
              <div className="cart-web-color-A "></div>
              <div className="cart-web-color-B "></div>
            </div>
          </div>

          <div id="MenuDos" className="card   call-center shadow-lg animacion">
          <h2 className="card-title text-light pt-4">MAPA DE OBRAS</h2>
            <a href="Obras-Publicas" className=" car-hover-a">
              <img src={mapa} className="card-img-top-1" alt="Mapa de obras" />
            </a>
            <div className="linea-f">
              <div className="cart-web-color-A "></div>
              <div className="cart-web-color-B "></div>
            </div>
          </div>

          <div id="MenuDos" className="card   call-center shadow-lg animacion">
            <h2 className="card-title text-light pt-4">SEM</h2>
            <a href="SEM" className=" car-hover-a">
              <img src={sem} className="card-img-top-1" alt="Sem app" />
            </a>
            <div className="linea-f">
              <div className="cart-web-color-A "></div>
              <div className="cart-web-color-B "></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MenuDos;
