import React, { Component } from "react";
import { Link } from "react-router-dom";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
import Checkbox from "../../images/iconos/checkbox-outline.svg";
class MenuConexionAgua extends Component {
  render() {
    return (
      <>
        <div class="App">
          <Nabvar />
          <div className="row m-2">
            <h1>Dirección de Obras Sanitarias</h1>

            <h3>Documentación necesaria para realizar el trámite</h3>
          </div>

          <div className="row Car-secondary-1">
            <h2>Conexion de Agua</h2>

            <p className="text-start img-p-h-c">
              <img src={Checkbox} /> ---------------
            </p>

            <ul>
              <li>
                <Link to="/Conexion-De-Agua">
                  <button className="button-Noticia">Conexion De Agua</button>
                </Link>
              </li>
            </ul>
          </div>
          <div className="row Car-secondary-1">
            <h2>Extencion de Agua</h2>

            <p className="text-start img-p-h-c">
              <img src={Checkbox} /> ---------------
            </p>

            <ul>
              <li>
                <Link to="/Extencion-De-Agua">
                  <button className="button-Noticia">Extención De Agua</button>
                </Link>
              </li>
            </ul>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}

export default MenuConexionAgua;
