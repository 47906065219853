import React from "react";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import logoMuni from "../../images/img/isotipo-B.svg";
const Footer = () => {
  return (
    <>
<footer className=' py-5 footer-mga shadow-lg mt-5'>
		<div className='row ml-2 '>
			<div className='col-12 col-md m-2' >
			   
				<h5 className='color-f img-F'><img    src={logoMuni} alt="logo MGA" /> Municipio</h5>
				<ul className='list-unstyled text-small'>
				<li><a className='link-secondary' href='/Intendencia'>Intendencia</a></li>
				<li><a className='link-secondary' href='/Gobierno'>Gobierno</a></li>
				
				<li><a className='link-secondary' href='/Planificacion-Obras-y-Servicios'>Planificación, Obras y Servicios</a></li>
					<li><a className='link-secondary' href='/Obras-Publicas'>Obras Públicas</a></li>
					<li><a className='link-secondary' href='/Salud'>Salud</a></li>
					<li><a className='link-secondary' href='/Desarrollo-Humano'>Desarrollo Humano</a></li>
					<li><a className='link-secondary' href='/Produccion-Emple-OMIC-y-Medio-Amb'>Secretaría de Producción, Desarrollo Local, Empleo y OMIC </a></li>
					<li><a className='link-secondary' href='/Turismo-Cultura-y-Deporte'>Turismo, Cultura y Deporte</a></li>
					<li><a className='link-secondary' href='/Derechos-Humanos'>Derechos Humanos</a></li>
					<li><a className='link-secondary' href='/Economia'>Economía</a></li>
					<li><a className='link-secondary' href='/HCD'>HCD</a></li>
					<li><a className='link-secondary' href='/Juzgado-de-Faltas'>Juzgado de Faltas</a></li>
				</ul>
				
			</div>
			<div className='col-6 col-md m-2'>
			    
				<h5 className='color-f'> <img    src={logoMuni} alt="logo MGA" /> Trámites y Servicios </h5>
				<ul className='list-unstyled text-small'>
				
				<li><a className='link-secondary' href='/CV-trayectoria-laborales'>CV y Trayectoria Laborales</a></li>
				<li><a className='link-secondary' href='/Licencia-De-Conducir'>Licencia de Conducir</a></li>
					<li><a className='link-secondary' href='/Boletin-Oficial-de-Derechos'>Boletín Oficial de Derechos</a></li>
					<li><a className='link-secondary' href='/Liquidacion-WEB'>Liquidacion WEB</a></li>
					<li><a className='link-secondary' href='/Calendario-de-Vencimientos'>Calendario de Vencimientos</a></li>
					<li><a className='link-secondary' href='/Formularios'>Formularios</a></li>
					<li><a className='link-secondary' href='/Boletin-Oficial'>Boletín Oficial</a></li>
					<li><a className='link-secondary' href='/Consultar-Deuda'>Consultar Deudas</a></li>
					<li><a className='link-secondary' href='/uso-interno'>USO INTERNO</a></li>
				</ul>
			</div>
			<div id='ga-1' className='col-6 col-md m-3 '>
				<h5 className='color-f'> <img   src={logoMuni} alt="logo MGA" /> + info </h5>

				<ul className='list-unstyled text-small'>
				<li><a className='link-secondary' href='/Noticias'>Noticias</a></li>
					<li><a className='link-secondary' href='/Historia'>Historia</a></li>
					<li><a className='link-secondary' href='/Localidades'>Localidades</a></li>
					<li><a className='link-secondary' href='/Fiestas-Regionales'>Fiestas Regioneles</a></li>
					<li><a className='link-secondary' href='/Protocolos-COVID'>Protocolos COVID</a></li>
					<li><a className='link-secondary' href='/Datos-Abiertos-Beta'>Datos Abiertos BETA</a></li>
					<li><a className='link-secondary' href='/Expo-Edicativa'>Expo Edicativa </a></li>
				</ul>
				<small className='d-block mb-3 text-muted'>&copy; 2019–2023</small>
			</div>
			
			
		</div>
	</footer>
    </>
  );
};
export default Footer;