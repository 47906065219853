import React, { Component} from "react";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import { Map, View } from "ol";
import OSM from "ol/source/OSM";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import "ol/ol.css";
import KML from "ol/format/KML";
import VectorSource from "ol/source/Vector";

class MapaObrasPublicas extends Component {
  constructor(props) {
    super(props);

    const VerdeEscN10 = new VectorLayer({
      source: new VectorSource({
        url: "/data/ObrasEducasion/1_verde_Escula_n_10_san_jose.kml",
        format: new KML(),
      }),
    });
    const VerdeEscN11 = new VectorLayer({
      source: new VectorSource({
        url: "/data/ObrasEducacion/2_verde_Escula_N_11.kml",
        format: new KML(),
      }),
    });
    const Verdecloacas = new VectorLayer({
      source: new VectorSource({
        url: "/data/ObrasNacionalInfraestructura/5_verde_cloacas_B_para.kml",
        format: new KML(),
      }),
    });

    this.state = {
      center: [0, 0],
      zoom: 0,
      myMap: new Map({
        target: document.getElementById("map-container"),
        layers: [
          new TileLayer({
            source: new OSM(),
            //iconos
          }),
          Verdecloacas,
          //layer
        ],
        view: new View({
          center: [-6441000, -4620000],
          zoom: 12,
        }),
      }),
    };
  }

  componentDidMount() {
    this.state.myMap.setTarget("map-container");
  }

  render() {
    return (
      <>
        <div className="map-container">
          <div id="map-container" />
          <div className="container-fluit menu">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-Rampas-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Rampas"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Obras Publicas{" "}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Cloacas"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Proyecto
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-Manteminiento-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Manteminiento"
                  type="button"
                  role="tab"
                  aria-controls="pills-Manteminiento"
                  aria-selected="false"
                >
                  {" "}
                  Ejecución
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-Red-de-Agua-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Red-de-Agua"
                  type="button"
                  role="tab"
                  aria-controls="pills-Red-de-Agua"
                  aria-selected="false"
                >
                  Terminada
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-Cordon-Cuneta-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Cordon-Cuneta"
                  type="button"
                  role="tab"
                  aria-controls="pills-Cordon-Cuneta"
                  aria-selected="false"
                >
                  {" "}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-Iluminacion-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Iluminacion"
                  type="button"
                  role="tab"
                  aria-controls="pills-Iluminacion"
                  aria-selected="false"
                >
                  {" "}
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-Rampas"
                role="tabpanel"
                aria-labelledby="pills-Rampas-tab"
              >
                <div className="card m-4 border-mga border-radius-mga">
                  <div className="card-header">Obras Publicas MGA</div>
                  <div className="card-body ">
                    <blockquote className="blockquote mb-0">
                      <p className="text-start">
                        Municipalidad de Gral. Alvarado (Calle 28 No 1084)
                      </p>
                      <p className="text-start">2° piso (02291) 42-0103</p>
                      <p className="text-start">obraspublicas@mga.gov.ar</p>
                      <footer className="blockquote-footer">
                        2019-2022-
                        <cite title="Source Title">MGA Gestion que Avanza</cite>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-Cloacas"
                role="tabpanel"
                aria-labelledby="pills-Cloacas-tab"
              >
                <div className="card m-4 border-mga border-radius-mga">
                  <div className="card-header">Proyectos en espera</div>
                  <div className="card-body ">
                    <blockquote className="blockquote mb-0">
                      <p className="text-start"></p>
                      <footer className="blockquote-footer">
                        2019-2022-
                        <cite title="Source Title">MGA Gestion que Avanza</cite>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-Manteminiento"
                role="tabpanel"
                aria-labelledby="pills-Manteminiento-tab"
              >
                <div className="card m-4 border-mga border-radius-mga">
                  <div className="card-header">Proyectos en Ejecución</div>
                  <div className="card-body ">
                    <blockquote className="blockquote mb-0">
                      <p className="text-start">
                        Escuela Agraria Miramar - 2da etapa
                      </p>
                      <p className="text-start">
                        {" "}
                        Escuela Educación Especial Nº 503
                      </p>
                      <p className="text-start">
                        {" "}
                        Asfaltado en Av. Chapar entre Av. del Mar y Av.
                        Erramouspe
                      </p>
                      <p className="text-start">
                        Proyecto asfalto y reasfaltado Gral. Alvarado
                      </p>
                      <p className="text-start">
                        Construcción de Cordón cuneta, badenes y asfalto en
                        Otamendi
                      </p>
                      <p className="text-start"></p>
                      <footer className="blockquote-footer">
                        2019-2022-
                        <cite title="Source Title">MGA Gestion que Avanza</cite>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-Red-de-Agua"
                role="tabpanel"
                aria-labelledby="pills-Red-de-Agua-tab"
              >
                <div className="card m-4 border-mga border-radius-mga">
                  <div className="card-header">Proyectos Terminados</div>
                  <div className="card-body ">
                    <blockquote className="blockquote mb-0">
                      <p className="text-start">
                        Escuela nº10 Paraje San Jose Ampliacion
                      </p>
                      <p className="text-start">
                        Escuela nº11 Miramar Reemplazo de cubiertas
                      </p>
                      <p className="text-start">
                        Escuela Agraria qMiramar: Impermeabilizacion de
                        cubiertas, reemplazo de carpinterias
                      </p>
                      <p className="text-start">
                        Escuela nº15 Paraje Santa Irene: Refaccion de cubiertas
                      </p>
                      <p className="text-start">
                        Escuela Construccion de Residencia Femenina
                      </p>
                      <p className="text-start">
                        Escuela nº17 Mejora en estructura de tanques de agua
                      </p>
                      <p className="text-start">
                        Escuela nº501 Mejora de cubiertas cielorrasos y
                        electricidad
                      </p>
                      <p className="text-start">
                        Escuela Primaria Nº 13 y Escuela Secundaria Nº 6
                      </p>
                      <p className="text-start">Obra de cloacas S.I.P</p>
                      <p className="text-start">Cloacas Barrio Los Paraisos</p>
                      <p className="text-start">Cloacas Barrio Parquemar</p>
                      <p className="text-start">Cloacas Barrio San Martin</p>
                      <p className="text-start">
                        Cordon cuneta realizado 2020-2021
                      </p>
                      <p className="text-start">
                        CAD Recambio de Artefactos Liminicos tipo L.E.D 70 WATTS
                        en el Partido de General Alvarado
                      </p>
                      <p className="text-start">
                        Corredor Luminico parque bristol, diagonal norte y
                        pueyrredon
                      </p>
                      <p className="text-start">Luminarias para localidades</p>
                      <p className="text-start">
                        Centro de Salud Mental y comunitario de Gral Alvarado
                      </p>
                      <p className="text-start">Punto Digital en C.N.O</p>
                      <p className="text-start">
                        juegos plaza Miramar, Otamendi, Mechongue, Mar del Sur
                      </p>
                      <p className="text-start">
                        Recambio de luminarias led y columnas
                      </p>
                      <p className="text-start">
                        Construcción de Rampas accesibles en la Ciudad de
                        Miramar
                      </p>
                      <p className="text-start">Centro de Dialisis</p>
                      <footer className="blockquote-footer">
                        2019-2022-
                        <cite title="Source Title">MGA Gestion que Avanza</cite>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-Cordon-Cuneta"
                role="tabpanel"
                aria-labelledby="pills-Cordon-Cuneta-tab"
              >
                <div className="card m-4 border-mga border-radius-mga">
                  <div className="card-header">Cordon Cuneta</div>
                  <div className="card-body ">
                    <blockquote className="blockquote mb-0">
                      <p className="text-start">
                        A well-known quote, contained in a blockquote element.
                      </p>
                      <footer className="blockquote-footer">
                        2019-2022-
                        <cite title="Source Title">MGA Gestion que Avanza</cite>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-Iluminacion"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="card m-4 border-mga border-radius-mga">
                  <div className="card-header">Iluminacion</div>
                  <div className="card-body ">
                    <blockquote className="blockquote mb-0">
                      <p className="text-start">
                        A well-known quote, contained in a blockquote element.
                      </p>
                      <footer className="blockquote-footer">
                        2019-2022-
                        <cite title="Source Title">MGA Gestion que Avanza</cite>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <div className="row"></div>
          </div>
        </div>
      </>
    );
  }
}
export default MapaObrasPublicas;
