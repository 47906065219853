import React, { Component} from 'react';
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';
class FiestaRegional extends Component {
    
      
  
    render() {
      return (
   <>
<Nabvar/>
   <Footer/>
  </>
      );
    }

    
    }
    
    export default FiestaRegional;