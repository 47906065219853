import React, { Component } from 'react';

class ExtencionDeAgua extends Component {
    
      
  
    render() {
      return (
   <>
 <iframe id="inlineFrameExample"
    title="Inline Frame Example"
    width="100%"
    height="800px"
    scrolling="yes"
  
    src="https://mgaservicios.online/index.php?entryPoint=ConexionesDeAgua" strict-origin-when-cross-origin>
</iframe>
  </>
      );
    }

    
    }
    
    export default ExtencionDeAgua ;