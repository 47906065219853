import React, { Component} from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
import intendente from "../../images/funcionarios/SEBASTIAN-IANANTUONY-Principal-768x768.jpg";
import icoGob from "../../images/Secretarias-Ico/01-secretaria-gobierno.svg";
import logo from "../../images/Logos/logo_a.png";
class Intendencia extends Component {
  render() {
    return (
      <>
        <div className="App">
          <Nabvar />

          
          <div className="row Car-secretarias color-gobierno animacion  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <div className="col clearfix">
              <div className=" text-left color-blanco">
                <h1>Intendencia</h1>
                <img className="img-secret-logo " src={logo} />
              </div>
            </div>
            <div className="col">
              <img className="img-secret" src={icoGob} />
            </div>
          </div>


          <div className="row  pt-2 mt-5 ">
            <div className="col img-intendente animacion ">
              <img className="figure-img img-fluid shadow-lg" src={intendente} />
            </div>
          </div>

          <div className="row Car-secondary-1 shadow-lg animacion">
            <h2>Intendente</h2>
            <h3>Mg. Sebastián Ianantuony</h3>
            <p>
              Nacido en la comunidad de Mechongué (20 de Febrero de 1982),
              estudió Administración y Marketing en la Universidad Nacional de
              Mar del Plata.
            </p>
            <p>
              Es Co-Director de la empresa de marketing para emprendedores
              “Buyido”, y Director de la revista web «MKT! Magazine», dedicada a
              la Pyme Iberoamericana.
            </p>
            <p>
              Fue Secretario de Producción, Desarrollo Local, Empleo y Medio
              Ambiente (2013 – 2019) durante la gestión del ex intendente Germán
              Di Cesare.
            </p>
            <p>
              En 2019 fue electo Intendente por el Frente de Todos, asumiendo el
              cargo el 12 de Diciembre de ese año
            </p>
          </div>
          <div className="row pb-5 pt-5">
           
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default Intendencia;
