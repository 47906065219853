import React, { Component } from "react";

import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
class SemApp extends Component {
  render() {
    return (
      <>
       <div class="App">
        <Nabvar />
        <iframe
          id="SemApp"
          title="Bienvenido a SEM General Alvarado "
          width="100%"
          height="800px"
          scrolling="yes"
          src="https://miramar.dat.cespi.unlp.edu.ar/#/login"
          strict-origin-when-cross-origin
        ></iframe>
        <Footer />
        </div>
      </>
    );
  }
}

export default SemApp;
