import React, { Component} from 'react';
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';
class Comunidad extends Component {
      /*
       
Nombre de Cuenta:* cre ar una cuanta 
Estado:           Origen: Web                   Fecha del Evento: 06/06/2020   Resolución: 
       Nuevo     Incidencia:1-Calles                    
       Asignado             2-Luminarias     
       Cerrado              3-Obras Sanitarias  Localidad: 01-Miramar 
       Duplicado            4-Residuos                     02-Otamendi
                            5-Poda                         03-Mechongue 
                            6-Transito                     04-Mar del Sur      
                            7-Seguridad                    05-Centinela 
                            8-ONGs
                            9-Rentas
                            10-Catastro
                            11-Reg. Dominial
                            12-Anses
                            13-Equipaniento
                            14-Zoonosis
                            15-Salud
                            16-Discapacidad
                            17-Infra Social
                            18-Asoc Civil
                            19-Desarrollo Humano
                            00-Otro
                            */
      
  
    render() {
      return (
   <>
   <div className="App">
   <Nabvar/>
   <Footer/>
   </div>
   
  </>
      );
    }

    
    }
    
    export default Comunidad;