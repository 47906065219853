import React, { Component} from 'react';

class ExpoEducativa extends Component {
    
 
  
    render() {
      return (
   <>
 <iframe id="inlineFrameExample"
          title="Inline Frame Example"
          width="100%"
          height="800px"
          scrolling="yes"
        
          src="https://mga.gov.ar/expoeducativa/" strict-origin-when-cross-origin>
      </iframe>
  </>
      );
    }

    
    }
    
    export default ExpoEducativa;