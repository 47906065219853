import React, { Component } from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
class ConexionDeCloacas extends Component {
  render() {
    return (
      <>
       <div class="App">
        <Nabvar />
        <iframe
          id="Seminfraccion"
          title="Infracciones de Estacionamiento medido en Genetal alvarado"
          width="100%"
          height="600px"
          scrolling="yes"
          src="https://miramar.dat.cespi.unlp.edu.ar/#/fines"
          strict-origin-when-cross-origin
        ></iframe>
        <Footer />
        </div>
      </>
    );
  }
}

export default ConexionDeCloacas;
