import React, { Component } from 'react';
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';
import DerechosHumanos from "../../images/Secretarias-Ico/12-secretarias-derechos-humanos.svg";
import logo from "../../images/Logos/logo_a.png";


class DerechoHumano extends Component {
    
      
  
    render() {
      return (
   <>
    <div className="App">
          <Nabvar />
          
          <div className="row Car-secretarias color-de-derechos-humanos animacion  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <div className="col text-left">
              <div className=" color-blanco ">
                <h1>Secretaria de Relaciones Institucionales y Derechos Humanos</h1>
                <img className="img-secret-logo" src={logo} />
              </div>
            </div>
            
            <div className="col">
              <img className="img-secret" src={DerechosHumanos} />
            </div>
          </div>

          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Secretario </h2>
            <h3> Dr. Fernando Bisciotti</h3>
            <p >Calle 21 No 1617 of 4 – (02291) 43-1953</p>
            <p >relinstityddhh@yahoo.com</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Derechos Humanos </h2>
            <h3>Norberto Miller</h3>
            <p >Calle 21 No 1617 of 4 – (02291) 43-1953</p>
            <p >relinstityddhh@yahoo.com</p>
          </div>
          <div className="row  animacion  shadow-lg   Car-secondary-1">
            <h2>Dirección de Políticas de Género </h2>
            <h3>Alejandra Carli</h3>
            <p >Calle 17 e/ 32 y 34 – Tel: (02291) 42-0611</p>
            <p >politicasdegenero@mga.gov.ar</p>
          </div>

   <Footer/>
   </div>
  </>
      );
    }

    
    }
    
    export default DerechoHumano;