import React, { Component } from "react";
import "../App.css";
import axios from "axios";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
import Slidershow from "../../components/Slidershow";

class Noticias extends Component {
  handleClick() {
    console.log("Se hizo click");
    alert(" Acepta recibir Notificaciones de Noticias de General Alavarado ?");
  }
  state = {
    noticias: [],
  };
  async componentDidMount() {
   await axios
      .get(`https://mgaservicios.online/api_prensa/v1/vernoticias?page=1`)
      .then((res) => {
        const noticias = res.data;
      
        this.setState({ noticias });
      })
      .catch(error=>{
        console.log(error);
      });
  }

  render() {
    return (
      <>
        
        <div className="App">
        <Nabvar />
        <Slidershow />
          <h1>Noticias MGA</h1>
          <button className="button-Noticia" onClick={() => this.handleClick()}>
            Suscribirme a Noticias MGA
          </button>

          <div id="Noticias">
            {this.state.noticias &&
              this.state.noticias.map((noticia) => (
                <div className="Noticia shadow-lg " key={noticia.NoticiaId}>
                  <h1>{noticia.Titulo}</h1>
                  <div className="footer-noticia">
                  <div>{noticia.Fecha} {noticia.Localiad}</div>
                  
                  </div>
                  <div className="noticia-centro">
                     <div className="ImgNoticia">
                      <img src={noticia.Imagen} />
                      </div>
                     <div className="ScrollNoticias">
                      <p>{noticia.Contenido}</p>
                     </div>
                    </div>
                  
                </div>
              ))}
          </div>
          <Footer />
        </div>
        
      </>
    );
  }
}

export default Noticias;
