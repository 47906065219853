import React from "react";
// Importing the Bootstrap CSS 
import "bootstrap/dist/css/bootstrap.min.css";
import consumidor from "../../images/MenuDos/defensa_consumidor.svg";
import habilitaciones from "../../images/MenuDos/habilitaciones_comerciales.svg";
import presentacion_planos from "../../images/MenuDos/presentacion_planos.svg";
import extraccionA from "../../images/MenuDos/extraccion_de_arbol.svg";
import indust from "../../images/MenuDos/radicacio_indus.svg";
import agua from "../../images/MenuDos/agua.svg";
import inspeccion from "../../images/MenuDos/inspeccion_de_obta.svg";
import cloacas from "../../images/MenuDos/cloaca.svg";
const MenuTres = () => {
  return (
    <>
       
        <div id="tramites" className="formularios container pb-5 mt-4 shadow-lg">
            <div className="row align-center ">
            
           
                    <div className="card-2 text-center  shadow animacion" >
                        <a href="Defensa-del-Consumidor" className=" car-hover-a ">
                            <div className="titulo-card">DEFENSA CONSUMIDOR</div>
                            <img src={consumidor}  className="card-img-top" alt="defensa consumidor" />
                        </a>
                        
                    </div>
            

        
               
                    <div className="card-2 text-center  shadow animacion" >
                        <a href="Habitaciones-Comerciales" className=" car-hover-a">
                            <div className="titulo-card">HABILITACIÓNES COMERCIALES</div>
                            <img src={habilitaciones} className="card-img-top" alt="habilitaciones comerciales" />
                        </a>
                       
                    </div>
           


        
              
                    <div className="card-2 text-center  shadow animacion" >
                        <a href="Presentacion-De-Planos" className=" car-hover-a">
                            <div className="titulo-card">PRESENTACION DE PLANOS </div>
                            <img src={presentacion_planos} className="card-img-top" alt="presentacion planos" />
                        </a>
                       
                    </div>
           
          
               
                    <div className="card-2 text-center  shadow animacion" >
                        <a href="Solicitud-Inspeccion" className=" car-hover-a">
                            <div className="titulo-card">SOLICITUD INSPECCION</div>
                            <img src={inspeccion} className="card-img-top" alt="inspeccion de obra" />
                        </a>
                       
                    </div>
           
            
      
                    <div className="card-2 text-center  shadow animacion" >
                        <a href="Menu-Conexion-Agua" className=" car-hover-a">
                            <div className="titulo-card">CONEXION DE AGUA</div>
                            <img src={agua} className="card-img-top" alt="agua" />
                        </a>
                       
                    </div>
               
              
             
                    <div className="card-2 text-center  shadow animacion" >
                        <a href="Menu-Conexion-Cloacas" className=" car-hover-a">
                            <div className="titulo-card">CONEXION DE CLOACAS</div>
                            <img src={cloacas} className="card-img-top" alt="cloacas " />
                        </a>
                       
                    </div>
             

           
               
                    <div className="card-2 text-center  shadow animacion" >
                        <a href="Radicacion-de-Industria" className=" car-hover-a">
                            <div className="titulo-card">RADICACIÓN DE INDUSTRIA</div>
                            <img src={indust} className="card-img-top" alt="radicasion de industria"/>

                        </a>
                        
                    </div>
               

        
                
                    <div className="card-2 text-center  shadow animacion" >
                        <a href="Extraccion-De-Arbol" className=" car-hover-a">
                            <div className="titulo-card">EXTRACION DE ARBOLES</div>
                            <img src={extraccionA} className="card-img-top" alt="extraccion de arbol" />
                        </a>

                  
                    </div>
              

            </div>
        </div>
  
    </>
  );
};
export default MenuTres;