
import React, { Component} from 'react';
import Footer from '../../components/Footer';

class LiquidacionWEB extends Component {
    
      
  
    render() {
      return (
         <>
        
         <iframe id="inlineFrameExample"
           title="Inline Frame Example"
           width="100%"
           height="800px"
           scrolling="yes"
           src="https://miram.ar/web/autosh/login" strict-origin-when-cross-origin>
       </iframe>
        <Footer/>
         </>
      );
    }

    
    }
    
    export default LiquidacionWEB;