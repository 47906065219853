import React, { Component } from 'react';
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';
import iconoTurismo from "../../images/Secretarias-Ico/05-secretaria-de-turismo-cultura-y-deporte.svg";
import logo from "../../images/Logos/logo_a.png";
class Turismo extends Component {
    
      
  
    render() {
      return (
   <>
 <div className="App">
          <Nabvar />
     
          <div className="row Car-secretarias color-turismo animacion  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <div className="col text-left">
              <div className=" color-blanco ">
                <h1>Secretaría Turismo, Cultura y Deporte</h1>
                <img className="img-secret-logo" src={logo} />
              </div>
            </div>
            
            <div className="col">
              <img className="img-secret" src={iconoTurismo} />
            </div>
          </div>

          <div className="row animacion  shadow-lg  Car-secondary-1">
            <h2>Secretario </h2>
            <h3> María Eugenia Bove</h3>
            <p>Calle 28 y Av. 23 </p>
            <p>turismo@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg  Car-secondary-1">
            <h2>Dirección de Promoción Turística</h2>
            <h3> Claudia Manso</h3>
            <p>Av. Costanera y calle 21 – (02291) 42-0190</p>
            <p>turismo@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg  Car-secondary-1">
            <h2>Dirección de Cultura</h2>
            <h3> Mariana Boh</h3>
            <p>Calle 38 No 1172 – (02291) 43-4333</p>
            <p>cultura@mga.gov.ar</p>
          </div>
          <div className="row animacion  shadow-lg  Car-secondary-1">
            <h2>Dirección de Deportes</h2>
            <h3>Julieta Lofeudo</h3>
            <p>Natatorio Municipal (Paseo Leloir s/n) – (02291) 43-1045</p>
            <p>deportes@mga.gov.ar</p>
          </div>


   <Footer/>
   </div>
  </>
      );
    }

    
    }
    
    export default Turismo;