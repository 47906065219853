import React, { Component} from 'react';
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';
import Checkbox from "../../images/iconos/checkbox-outline.svg";


class ParqueIndustrial extends Component {
    
      
  
    render() {
      return (
   <>
     <div className="App">
          <Nabvar />
          <div className="row m-2">
            <h1>Dirección de Obras Privadas</h1>
            <h2>Presentacion De Planos</h2>
            <h3>
              Documentación necesaria para realizar el trámite 
            </h3>
          </div>
          <div className="row p-3">
            <p className="text-start img-p-h-c">
             
              <img src={Checkbox} /> ---------------
            </p>
           
          </div>
          <div className="row p-5">
            <a href="#">
              <button className="button-Noticia">
                Iniciar Solicitud tramite Click Aqui
              </button>
            </a>
          </div>
          <Footer />
        </div>
   
  </>
      );
    }

    
    }
    
    export default ParqueIndustrial;