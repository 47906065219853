import React, { Component } from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
import Checkbox from "../../images/iconos/checkbox-outline.svg";

class HabilitacionesComerciales extends Component {
  render() {
    return (
      <>
        <div className="App">
          <Nabvar />
          <div className="row  animacion  m-2">
            <h1>DIRECCIÓN DE INSPECCIÓN GENERAL</h1>
            <h2>Habilitación de comercios</h2>
            <h3>
              Documentación necesaria para realizar el trámite de habilitación
            </h3>
          </div>
          <div className="row  animacion  p-3">
            <p className="text-start img-p-h-c">
             
              <img src={Checkbox} /> Contrato de locación (timbrado en el Banco
              de la Provincia de Bs. As y con firmas certificadas) o título de
              propiedad (en ambos casos fotocopia certificada).
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Certificado urbanístico (se solicita en
              Dirección de Catastro Municipal).
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Tres croquis de la planta del local,
              indicando metros cuadrados del local a utilizar, dimensiones
              generales, equipamientos fijos (hornos, mesadas, cocinas, etc.) y
              tipos de solados, corte transversal y longitudinal, indicando
              alturas libres y bajo vigas, revestimientos y su altura, sistema
              de ventilación de aquellos elementos que así lo requieran
              (calefones, chimeneas, etc.).
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Asesoramiento técnico para matafuegos y
              certificado de aptitud contra incendios expedido por el cuartel.
              de bomberos.
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Activo fijo con los valores reales.
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Declaración de inmuebles.
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Contrato social (fotocopia certificada).
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Fotocopia de documento de identidad
              completo DNI/lE/LC
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Cedula de identidad (solamente en caso de
              ser extranjero).
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Constancia de domicilio.
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Inscripción en rentas por ingresos brutos.
              (fotocopia)
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Número de CUIT o monotributo (fotocopia).
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> Recibo de pago a la caja de autónomos o
              monotributo (fotocopia).
            </p>
            <p className="text-start img-p-h-c">
              {" "}
              <img src={Checkbox} /> El o los solicitantes no deben revestir el
              carácter de morosos ante la municipalidad. como así tampoco debe
              serlo la unidad funcional a habilitar (por tasa por A.B.L. y
              servicios sanitarios; derechos de construcción y tasa por
              seguridad e higiene debiendo presentar libre deuda emitido por la
              D.I.P.).
            </p>
          </div>
          <div className="row  animacion  p-5">
            <a href="#">
              <button className="button-Noticia">
                Iniciar Solicitud tramite Click Aqui
              </button>
            </a>
          </div>

          <Footer />
        </div>
      </>
    );
  }
}

export default HabilitacionesComerciales;
