import React from "react";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../images/Logos/logo_a.png";
import { Notifications } from 'react-push-notification';
const Nabvar = () => {
  return (
    <>
      <header id="navbar" className="masthead shadow-lg mb-5" role="banner">
      <Notifications />
        <Navbar>
        <div className="nav-menu container-fluid">
          <Navbar.Brand href="/">
            <img src={logo} alt="logo MGA" />
          </Navbar.Brand>
          <a href="/#ATENCION-AL-VECINO" className="text-light">ATENCIÓN AL VECINO</a><div className="text-light">|</div>
          <a href="/#tramites" className="text-light">TRAMINTES</a><div className="text-light">|</div>
          <a href="/Agenda" className="text-light">AGENDA</a><div className="text-light">|</div>
          <a href="/Turismo-Cultura-y-Deporte" className="text-light">TURISMO</a><div></div>
         </div>
        </Navbar>
      </header>
    </>
  );
};
export default Nabvar;
