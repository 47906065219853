
import React, { Component } from "react";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import { Map, View } from 'ol';

import OSM from 'ol/source/OSM';
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import 'ol/ol.css';
import KML from "ol/format/KML";
import VectorSource from "ol/source/Vector";



class MapaSem extends Component{
  
  constructor(props) {
    super(props);
    
    const SemTrazado = new VectorLayer({
      source: new VectorSource({
        url:"http://localhost:3000/data/sem/sem.kml",
        format: new KML(),
      }),
    });
    

    
    this.state = {
        center: [0, 0],
        zoom: 0,
        myMap: new Map({
                          target: document.getElementById('map-container'),
                            layers: [
                                new TileLayer({
                                    source: new OSM(),
                                    //iconos
                                }),SemTrazado
                                //layer
                            ],
                            view: new View({
                              center: [-6441000, -4620000],
                              zoom: 12,
                            })
                      })
      };
  }
  
  componentDidMount() {
      
      this.state.myMap.setTarget("map-container");
     
  }


  render(){
    return (
        
        <div style={{height:'500px',width:'auto',}} id="map-container" className="map-container" />
    );

    }
}
export default MapaSem;