import React, { Component } from 'react';
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';


class Hcd extends Component {
    
      
  
    render() {
      return (
   <>
    <div className="App">
          <Nabvar />
          <div className="row pb-5 pt-5">
            <h1>HCD</h1>
          </div>
          <div className="row Car-secondary-1">
            <h2>Autoridades Período 2021/2025 </h2>
            <h3>Presidente Honorable Concejo Deliberante</h3>
            <p >HONORES, Lucas Marcelo</p>
            
          
          </div>
          <div className="row Car-secondary-1">
            <h2>Autoridades Período 2021/2025 </h2>
            <h3>Vicepresidente 1º</h3>
            <p >VUJOVICH, Sabina Cora</p>
          </div>
          <div className="row Car-secondary-1">
            <h2>Autoridades Período 2021/2025 </h2>
            <h3>Secretario</h3>
            <p >TONI, Francisco </p>
          </div>
          <div className="row Car-secondary-1">
            <h2>Conformación de los Bloques Políticos </h2>
            <h3>BLOQUE FRENTE DE TODOS </h3>
            <p >Teléfono (02291) 431241- 431245 – Int. 233 – Mail: frentetodosgralalvarado@gmail.com</p>
          </div>

   <Footer/>
   </div>
  </>
      );
    }

    
    }
    
    export default Hcd;