import React, { Component} from 'react';
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';
import icosobras from "../../images/Secretarias-Ico/06-secretaria-obras-publicas.svg";
import logo from "../../images/Logos/logo_a.png";
class Planification extends Component {
    
      
  
    render() {
      return (
   <>
 <div className="App">
          <Nabvar />

          <div className="row Car-secretarias color-obras-publicas animacion  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <div className="col text-left">
              <div className=" color-blanco ">
                <h1>Secretaría de Planificación, Obras y Servicios</h1>
                <img className="img-secret-logo" src={logo} />
              </div>
            </div>
            
            <div className="col">
              <img className="img-secret" src={icosobras} />
            </div>
          </div>


          <div className="row  animacion  shadow-lg Car-secondary-1">
            <h2>Secretario </h2>
            <h3>CPN Martín Scarpignato</h3>
            <p>Municipalidad de Gral. Alvarado (Calle 28 No 1084) </p>
            <p>2° piso (02291) 42-0103</p>
            <p>obraspublicas@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg Car-secondary-1">
            <h2>Subsecretaría de Servicios</h2>
            <h3>Mariano Auriti</h3>
            <p>Oficina del Vivero Municipal</p>
            <p>Tel: (02291) 43-1233</p>
          </div>
          <div className="row  animacion  shadow-lg Car-secondary-1">
            <h2>Dirección de Obras Privadas</h2>
            <h3>Claudia Ambrossi</h3>
            <p>Municipalidad de Gral. Alvarado (Calle 28 No 1084) 2° Piso – (02291) 42-2164</p>
            <p>obrasprivadas@mga.gov.ar</p>
          </div>

          <div className="row  animacion  shadow-lg Car-secondary-1">
            <h2>Dirección de Obras Sanitarias</h2>
            <h3>Martín Beraldo</h3>
            <p>Calle 40 esq. 11- (02291) 43-1424</p>
            <p>obrassanitarias@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg Car-secondary-1">
            <h2>Dirección de Obras Públicas</h2>
            <h3>ARQ. Braian Benítez</h3>
            <p>Municipalidad de Gral. Alvarado (Calle 28 No 1084) 2º – (02291) 42-9018</p>
            <p>dop@mga.gov.ar</p>
          </div>
          <div className="row  animacion  shadow-lg Car-secondary-1">
            <h2>Dirección de Servicios</h2>
            <h3>Sergio Tilaro</h3>
            <p>OCorralón Municipal (calle 52 e/ 5 y 7, Miramar).</p>
            <p>Residuos y Bacheo / Electrotecnia: 43-1777 / 43-0801</p>
            <p>servicios@mga.gov.ar</p>
          </div>
          


   <Footer/>
   </div>
  </>
      );
    }

    
    }
    
    export default Planification;