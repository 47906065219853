import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';
import Slidershow from '../../components/Slidershow';
class UsoInterno extends Component {
    
    render() {
   
      return (
   <>
   <div className="App">
   <Nabvar/>
   <Slidershow/>
   <div class="container pt-5 pb-5">
   <ul > 
        <li class="pt-2"><Link to="/AccesoHaberes"><button className="button-Noticia">Acceso a mis haberes</button></Link></li>
        <li class="pt-2"><Link to="/AccesoApoderadoExterno"><button className="button-Noticia" >Acceso apoderados externos</button></Link></li>
    </ul>
  </div>
    <Footer/>
    </div>
  </>
      );
    }

    
    }
    
    export default UsoInterno;