import React, { Component } from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
class ConexionDeCloacas extends Component {
  render() {
    return (
      <>
       <div class="App">
        <Nabvar />
        <iframe
          id="inlineFrameExample"
          title="Inline Frame Example"
          width="100%"
          height="800px"
          scrolling="yes"
          src="https://mgaservicios.online/index.php?entryPoint=ConexionesDeCloacas"
          strict-origin-when-cross-origin
        ></iframe>
        <Footer />
        </div>
      </>
    );
  }
}

export default ConexionDeCloacas;
