import React, { Component} from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
class Localidades extends Component {
  render() {
    return (
      <>
      <div className="App">
        <Nabvar />

        <div className="row pb-5 pt-5">
        <h1>Gral. Alvarado</h1>
        <p>Se encuentra al sudeste de la provincia de Bs. As., sobre las márgenes del Mar Argentino. Su ciudad cabecera es Miramar. Limita al noreste con el partido de General Pueyrredón (Mar del Plata); al noroeste con el partido de Balcarce; al sudoeste con el partido de Lobería y al sudeste con el mar Argentino. Forma parte de la Quinta Sección Electoral de la provincia de Buenos Aires.</p>
        <p>Superficie: Urbana: 48,6 km2 – Rural: 1551,4 Km2 – Total: 1.600 Km2.</p>
        <p>Población: urbana: 37.373 Hab. – Rural: 2.221 Hab. – Total: 39.594 Hab.
(Censo 2010)</p>
        </div>
        <div className="row pb-5 pt-5"> <h1>Localidades</h1></div>
        
          <div className="row Car-secondary-1">
            <h3>Miramar</h3>
            <p >Ciudad cabecera del Partido de Gral. Alvarado, Con una magnífica costa con amplias playas de suave arena, que brindan todas las posibilidades para disfrutar del sol y el mar.</p>
            <p >Fundación: 20 de septiembre de 1888.</p>
          </div>
          <div className="row Car-secondary-1">
            <h3>Mar del Sur</h3>
            <p >Villa balnearia ubicada a 17 km. al sur de Miramar, uno de los lugares de mayor trayectoria histórica del Partido de General Alvarado, con amplias y agrestes playas que la distinguen entre las localidades costeras de la Provincia de Buenos Aires.</p>
            <p >Fundación: 4 de febrero de 1889</p>
          </div>
          <div className="row Car-secondary-1">
            <h3>Comandante Nicanor Otamendi</h3>
            <p >Ubicada a 33 km de la ciudad de Miramar. Es la principal localidad de producción agrícola del Partido de General Alvarado. Zona papera por excelencia, que la ubican como un referente relevante a nivel provincial.</p>
            <p >Fundación: 29 de mayo de 1911</p>
          </div>
          <div className="row Car-secondary-1">
            <h3>Mechongué</h3>
            <p >UUbicada al oeste del Partido de General Alvarado (camino a Balcarce) a 42 km de la ciudad de Miramar. Hermosa comunidad, conocida tradicionalmente como el “Pago Lindo”. Destacada en la producción ganadera del distrito.</p>
            <p >Fundación: 29 de mayo de 1911</p>
          </div>
          <div className="row Car-secondary-1">
            <h3>Centinela del Mar</h3>
            <p >Agreste comunidad, ubicada a 38 km. al sur de la villa balnearia de Mar del Sud. Posee encantadoras playas y acantilados. Zona pesquera por excelencia. Reserva natural de importante valor arqueológico y paleontológico. Se accede por caminos vecinales desde Mar del Sur o por Ruta Provincial No 88 (camino a Necochea).</p>
            <p >Se incorporó al Partido de General Alvarado en 1959.</p>
          </div>
        <Footer />
        </div>
      </>
    );
  }
}

export default Localidades;
