import React, { Component } from 'react';
import Nabvar from '../../components/Nabvar';
import Footer from '../../components/Footer';


class JuzgadodeFaltas extends Component {
    
      
  
    render() {
      return (
   <>
 <div className="App">
          <Nabvar />
          <div className="row  animacion  rounded-4 shadow-lg   pb-5 pt-5 mt-5">
            <h1>JUZGADO DE FALTAS</h1>
          </div>
          <div className="row  animacion  shadow-lg Car-secondary-1">
            <h2>Jueza </h2>
            <h3> Dra. Berta Juliano.</h3>
            <p >Calle 17 N° 1630 – Tel. (02291) 42-3689</p>
            <p >Juzgadofaltasmiramar@hotmail.com</p>
          </div>
          

   <Footer/>
   </div>
  </>
      );
    }

    
    }
    
    export default JuzgadodeFaltas;