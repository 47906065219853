import React, { Component} from "react";
import Checkbox from "../../images/iconos/checkbox-outline.svg";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
class ExtraccionDeArbol extends Component {
  render() {
    return (
      <>
        <div className="App">
          <Nabvar />
          <div className="row m-2">
            <h1>Dirección Forestal</h1>
            <h2>Extraccion De Arbol</h2>
            <h3>
              Documentación necesaria para realizar el trámite de  inspección y aprobación de Extraccion De Arboles
            </h3>
          </div>
          <div className="row p-3">
            <p className="text-start img-p-h-c">
             
              <img src={Checkbox} /> ---------------
            </p>
           
          </div>
          <div className="row p-5">
            <a href="#">
              <button className="button-Noticia">
                Iniciar Solicitud tramite Click Aqui
              </button>
            </a>
          </div>
      
          <Footer />
        </div>
      </>
    );
  }
}

export default ExtraccionDeArbol;
