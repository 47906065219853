import React, { Component} from "react";
import Nabvar from "../../components/Nabvar";
import Footer from "../../components/Footer";
class ConexionDeAgua extends Component {
  render() {
    return (
      <>
        <Nabvar />
        <Footer />
      </>
    );
  }
}

export default ConexionDeAgua;
